<script setup lang="ts">
import { useValidation } from 'vue3-form-validation'
import parsePhoneNumber from 'libphonenumber-js'
import { useReCaptcha } from 'vue-recaptcha-v3'

const { t, locale } = useI18n()
const recaptchaInstance = useReCaptcha();

const { form, validateFields, resetFields } = useValidation({
    firstName: {
        $value: '',
        $rules: [
            (firstName: string) => {
                if (!firstName) {
                    return t('error.firstnameRequired')
                }
            }
        ]
    },
    lastName: {
        $value: '',
        $rules: [
            (lastName: string) => {
                if (!lastName) {
                    return t('error.lastnameRequired')
                }
            }
        ]
    },
    email: {
        $value: '',
        $rules: [
            (email: string) => {
                if (!email) {
                    return t('error.emailRequired')
                } else if (!email.match(/\S+@\S+\.\S+/)) {
                    return t('error.invalidEmail')
                }
            }
        ]
    },
    phone: {
        $value: '',
        $rules: [
            (phone: string) => {
                if (phone) {
                    const phoneNumber = parsePhoneNumber(phone, 'HR')
                    if (!phoneNumber?.isValid()) {
                        return t('error.invalidPhone')
                    }
                }
            }
        ]
    },
    message: {
        $value: '',
        $rules: [
            (message: string) => {
                if (!message) {
                    return t('error.messageRequired')
                }
            }
        ]
    },
    terms: {
        $value: false,
        $rules: [
            (terms: boolean) => {
                if (!terms) {
                    return t('error.termsRequired')
                }
            }
        ]
    },
})

const submit = async () => {
    await recaptchaInstance?.recaptchaLoaded()
    const token = await recaptchaInstance?.executeRecaptcha('submit')
    await validateFields().then(async () => {
        if (token) {
            await $fetch("/api/email", {
                method: "POST",
                body: {
                    name: `${form.firstName.$value} ${form.lastName.$value}`,
                    email: form.email.$value,
                    phone: form.phone.$value,
                    message: form.message.$value
                },
            });
            await resetFields()
            useNuxtApp().$toast.success(t('notification.contact'))   
        } else {
            useNuxtApp().$toast.error(t('error.invalidRecaptcha'))
        }
    }).catch((err: Error) => {})
}

const privacyPolicy = computed(() => {
    if (locale.value === 'en') {
        return '/pdf/privacy-policy.pdf'
    } else {
        return '/pdf/politika-privatnosti.pdf'
    }
})

const termsAndConditions = computed(() => {
    if (locale.value === 'en') {
        return '/pdf/terms-and-conditions.pdf'
    } else {
        return '/pdf/uvjeti-i-pravila-koristenja.pdf'
    }
})
</script>

<template>
    <form class="form" @submit.prevent="submit">
        <UiFormItem :label="$t('contactData.firstName')" :error-message="form.firstName.$errors">
            <UiInput :placeholder="$t('contactData.yourFirstName')" v-model="form.firstName.$value" />
        </UiFormItem>
        <UiFormItem :label="$t('contactData.lastName')" :error-message="form.lastName.$errors">
            <UiInput :placeholder="$t('contactData.yourLastName')" v-model="form.lastName.$value" />
        </UiFormItem>
        <UiFormItem :label="$t('contactData.email')" :error-message="form.email.$errors">
            <UiInput placeholder=" name@example.com" v-model="form.email.$value" />
        </UiFormItem>
        <UiFormItem :label="$t('contactData.phoneNumber')" :error-message="form.phone.$errors">
            <UiInput placeholder="+(12) 345 6789" v-model="form.phone.$value" />
        </UiFormItem>
        <UiFormItem :label="$t('contactData.yourMessage')" class="sm:col-span-2" :error-message="form.message.$errors">
            <UiTextarea :placeholder="$t('contactData.yourMessage')" v-model="form.message.$value" />
        </UiFormItem>
        <UiFormItem class="sm:col-span-2" :error-message="form.terms.$errors">
            <UiCheckbox id="terms" v-model="form.terms.$value">
                {{ $t('contactData.submitMessage') }} <a :href="termsAndConditions" target="_blank"
                    class="text-[color:var(--primary)] underline font-medium">{{ $t('contactData.terms') }}</a> {{
                        $t('contactData.and') }} <a :href="privacyPolicy" target="_blank" class="text-[color:var(--primary)] underline font-medium">{{
                    $t('contactData.privacyStatement') }}</a>
            </UiCheckbox>
        </UiFormItem>
        <UiButton class="w-fit">{{ $t('action.send') }}</UiButton>
    </form>
</template>

<style lang="scss" scoped>
.form {
    @apply grid grid-cols-1 sm:grid-cols-2 gap-6 max-w-3xl mx-auto;
}
</style>
