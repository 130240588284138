<script setup lang="ts">
const { t } = useI18n()
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules'
import 'swiper/css';
import 'swiper/css/navigation';

const ratings = ref([
{
    name: t('review.answer1.name'),
    location: t('review.answer1.location'),
    description: t("review.answer1.desc")
  },
  {
    name: t('review.answer2.name'),
    location: t('review.answer2.location'),
    description: t("review.answer2.desc")
  },
  {
    name: t('review.answer3.name'),
    location: t('review.answer3.location'),
    description: t("review.answer3.desc")
  }
]);
const modules = [Navigation]
</script>

<template>
  <section class="rating">
    <div class="rating__inner">
      <swiper
        :slides-per-view="1"
        :loop="true"
        :modules="modules"
        :navigation="{
          nextEl: '.custom-next-arrow',
          prevEl: '.custom-prev-arrow',
        }"
      >
        <swiper-slide v-for="(rating, index) in ratings" :key="index">
          <p class="text-2xl font-semibold text-[color:var(--primary)] mb-8">“{{ rating.description }}“</p>
          <p>
            <span>{{ rating.name }} /</span> {{ rating.location }}
          </p>
        </swiper-slide>
      </swiper>
      <div class="arrows">
        <div class="custom-arrow custom-prev-arrow">
          <SvgoArrowLeft class="w-5" />
        </div>
        <div class="custom-arrow custom-next-arrow">
          <SvgoArrowRight class="w-5" />
        </div>
      </div>
      
    </div>
  </section>
</template>

<style lang="scss" scoped>
.rating {
  @apply bg-gray-100 py-24 px-4;
  &__inner {
    @apply max-w-3xl mx-auto;
    .swiper {
      .swiper-wrapper {
        .swiper-slide {
          @apply text-center;
          p {
            span {
              @apply text-[color:var(--primary)] font-semibold;
            }
          }
        }
      }
    }
    .arrows {
      @apply mt-8 flex gap-6 w-fit mx-auto;
      .custom-arrow {
        @apply text-gray-500 cursor-pointer;
      }
    }
  }
}
</style>
