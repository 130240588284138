<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';

const { t } = useI18n()
const search = ref("");

const options = ref([
  {
    icon: "clipboard",
    title: t("question.general.title"),
    questions: [t("question.general.desc1")]
  },
  {
    icon: "credit-card",
    title: t("question.license.title"),
    questions: [t("question.license.desc1")]
  },
  {
    icon: "receipt",
    title: t("question.refund.title"),
    questions: [t("question.refund.desc1")]
  },
  {
    icon: "user",
    title: t("question.account.title"),
    questions: [t("question.account.desc1")]
  },
]);

const searchedOptions = computed(() => {
  if (search.value) {
    return options.value.filter((option) =>
      option.title.toLowerCase().includes(search.value.toLowerCase())
    );
  } else {
    return options.value;
  }
});
</script>

<template>
  <section class="container">
    <div class="container__inner">
      <div class="md:max-w-[672px] md:mx-auto">
        <h2 class="mb-4 md:text-center">{{ $t('question.title') }}</h2>
        <p
          class="mb-8 md:text-center text-base md:text-xl font-normal text-gray-500"
        >
        {{ $t('question.description') }}
        </p>
        <UiInput v-model="search" :placeholder="$t('question.type')" variant="ghost">
          <template #icon-left>
            <SvgoSearch class="w-5 text-gray-500" />
          </template>
        </UiInput>
        <p class="text-sm mt-4 md:text-center">
          {{ $t('question.topic') }}
        </p>
      </div>
      <div class="mt-16">
        <swiper
        :slides-per-view="1"
        :space-between="32"
        :loop="false"
        :breakpoints="{
          '500': {
            slidesPerView: 2,
            spaceBetween: 32,
          },
          '768': {
            slidesPerView: 3,
            spaceBetween: 32,
          },
          '1024': {
            slidesPerView: 4,
            spaceBetween: 32,
          },
        }"
      >
        <swiper-slide v-for="(option, index) in searchedOptions" :key="index">
          <UiCard
          size="sm"
        >
          <div class="icon">
            <SvgoClipboard
              v-if="option.icon === 'clipboard'"
              class="h-6 text-[color:var(--secondary)]"
            />
            <SvgoCreditCard
              v-if="option.icon === 'credit-card'"
              class="h-6 text-[color:var(--secondary)]"
            />
            <SvgoReceipt
              v-if="option.icon === 'receipt'"
              class="h-6 text-[color:var(--secondary)]"
            />
            <SvgoUser
              v-if="option.icon === 'user'"
              class="h-6 text-[color:var(--secondary)]"
            />
          </div>
          <p class="text-[color:var(--primary)] font-semibold mb-4">
            {{ option.title }}
          </p>
          <p
            v-for="(question, index) in option.questions"
            :key="index"
            class="text-sm mb-3"
          >
            {{ question }}
          </p>
        </UiCard>
        </swiper-slide>
      </swiper>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.container {
  @apply px-4 max-w-full py-16 bg-gray-100;
  &__inner {
    @apply max-w-7xl mx-auto;
    .icon {
      @apply w-12 h-12 rounded-lg flex items-center justify-center bg-[color:var(--orange-500)] mb-5;
    }
    .card {
      @apply h-full;
      max-width: initial !important;
    }
    .swiper-slide {
      height: auto;
    }
  }
}
</style>
