<script setup lang="ts">
import CoverSection from '@/components/sections/CoverSection.vue'
import ReviewSection from '@/components/sections/ReviewSection.vue'
import TwoColumnSection from '@/components/sections/TwoColumnSection.vue'
import PlanSection from '@/components/sections/PlanSection.vue'
import QuestionSection from '@/components/sections/QuestionSection.vue'
import ContactSection from '@/components/sections/ContactSection.vue'

const readMore = ref<string[]>([])

const isVisible = (element: string) => {
  return readMore.value.includes(element)
}

const toggleRead = (element: string) => {
  if (readMore.value.includes(element)) {
    const index = readMore.value.indexOf(element)
    if (index > -1) {
      readMore.value.splice(index, 1)
    }
  } else {
    readMore.value.push(element)
  }
}

const scrollToElement = (elementId: string) => {
    let scrollDiv = document.getElementById(elementId)?.offsetTop || 0;
    window.scrollTo({ top: scrollDiv - 156, behavior: 'smooth' });
}
</script>

<template>
    <div>
      <CoverSection id="home" />
      <ReviewSection id="about" />
      <TwoColumnSection id="features">
        <template #image>
          <img class="image" src="/images/transport.webp" alt="Request transport">
        </template>
        <template #content>
          <h2 class="mb-6">{{ $t('request.request.title') }}</h2>
          <p>{{ $t('request.request.description') }}</p>
          <p v-if="isVisible('request')" class="mt-2">{{ $t('request.request.descriptionMore') }}</p>
          <div class="flex gap-2 mt-6">
            <UiButton @click="toggleRead('request')">{{ isVisible('request') ? $t('action.readLess') : $t('action.readMore') }} <SvgoArrowRight class="w-[14px] ml-2" /></UiButton>
          </div>
        </template>
      </TwoColumnSection>
      <TwoColumnSection reverse>
        <template #image>
          <img class="image" src="/images/offer.webp" alt="Offer transport">
        </template>
        <template #content>
          <h2 class="mb-6">{{ $t('request.offer.title') }}</h2>
          <p>{{ $t('request.offer.description') }}</p>
          <p v-if="isVisible('offer')" class="mt-2">{{ $t('request.offer.descriptionMore') }}</p>
          <div class="flex gap-2 mt-6">
            <UiButton @click="toggleRead('offer')">{{ isVisible('offer') ? $t('action.readLess') : $t('action.readMore') }} <SvgoArrowRight class="w-[14px] ml-2" /></UiButton>
            <UiButton variant="ghost" @click="scrollToElement('pricing')">{{ $t('action.seeAll') }}</UiButton>
          </div>
        </template>
      </TwoColumnSection>
      <TwoColumnSection>
        <template #image>
          <img class="image" src="/images/notify.webp" alt="Notify others of your free slot">
        </template>
        <template #content>
          <h2 class="mb-6">{{ $t('request.notify.title') }}</h2>
          <p>{{ $t('request.notify.description') }}</p>
          <!-- <div class="flex gap-2 mt-6">
            <UiButton>{{ $t('action.readMore') }} <SvgoArrowRight class="w-[14px] ml-2" /></UiButton>
          </div> -->
        </template>
      </TwoColumnSection>
      <PlanSection id="pricing" />
      <QuestionSection />
      <ContactSection id="contact" />
    </div>
</template>

<style scoped>
.image {
  @apply shadow-lg block w-full rounded-lg h-full object-cover min-h-[350px] max-h-[350px] md:max-h-full;
}
</style>