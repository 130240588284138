<script setup lang="ts">
</script>

<template>
    <section class="container">
        <div class="container__inner">
            <div class="container__left">
                <h1>{{ $t('cover.title') }}</h1>
                <p>{{ $t('cover.description') }}</p>
                <div class="apps">
                    <a href="https://play.google.com/store/apps/details?id=lo.vozi.app&pli=1" target="_blank"><SvgoGooglePlay /></a>
                    <a href="https://apps.apple.com/hr/app/vozi-lo/id6478529535?l=hr" target="_blank"><SvgoAppStore /></a>
                </div>
            </div>
            <div class="container__right">
                <img class="image" src="/images/iPhone.webp" alt="iPhone">
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.container {
    @apply px-4 max-w-full;
    &__inner {
        @apply max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center md:min-h-[calc(100vh-88px)];
    }
    &__left {
        @apply text-center md:text-left;
        h1 {
            //@apply mb-6 md:max-w-[460px];
            @apply mb-6;
        }
        p {
            @apply mb-6;
        }
        .apps {
            @apply flex gap-4 w-fit mx-auto md:ml-0;
            a {
                &:first-child {
                    svg {
                        @apply max-w-52;
                    }
                }
                &:last-child {
                    svg {
                        @apply max-w-[188px];
                    }
                }
            }
            svg {
                @apply w-full;
            }
        }
    }
    &__right {
        @apply flex justify-center md:justify-end;
        .image {
            @apply block w-full max-w-[427px] pl-[50px] md:pl-6;
        }
    }
}
</style>