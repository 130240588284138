<script setup lang="ts">
const { isIos, isMacOS, isApple } = useDevice();

const isYearly = ref(false)

const price = computed(() => {
    if (isYearly.value) {
        return '999.99'
    } else {
        return '99.99'
    }
})

const storeLink = computed(() => {
    if (isIos || isMacOS || isApple) {
        return 'https://apps.apple.com/hr/app/vozi-lo/id6478529535?l=hr'
    } else {
        return 'https://play.google.com/store/apps/details?id=lo.vozi.app&pli=1'
    }
})
</script>

<template>
    <section class="container">
        <div class="container__inner">
            <h2 class="md:text-center mb-4">{{ $t('business.title') }}</h2>
            <p class="md:text-center mb-4 max-w-3xl mx-auto">{{ $t('business.description') }}</p>
            <div class="flex items-center gap-4 w-fit mx-auto">
                <p :class="[
                    'text-base duration-300',
                    { 'text-[color:var(--primary)]': !isYearly }
                ]">{{ $t('business.monthly') }}</p>
                <UiToggle v-model="isYearly" />
                <p :class="[
                    'text-base duration-300',
                    { 'text-[color:var(--primary)]': isYearly }
                ]">{{ $t('business.yearly') }}</p>
            </div>
            <div class="plan">
                <UiCard>
                    <h3 class="text-center mb-4">{{ $t('business.lookForARide') }}</h3>
                    <span class="text-center block text-5xl font-extrabold mb-4">0.99€</span>
                    <p class="text-center text-base mb-1">{{ $t('business.perAd') }}</p>
                    <a :href="storeLink" target="_blank" class="download link-btn link-btn--main mt-8" variant="dark">{{ $t('business.downloadApp') }}</a>
                    <ul class="offers">
                        <li><SvgoFire class="min-w-4" /> <span>{{ $t('business.plan.request.desc1') }}</span></li>
                        <li><SvgoFaceGrinStars class="min-w-4" /> <span>{{ $t('business.plan.request.desc2') }}</span></li>
                        <li><SvgoCreditCard class="min-w-4" /> <span>{{ $t('business.plan.request.desc3') }}</span></li>
                        <li><SvgoDiscount class="min-w-4" /> <span>{{ $t('business.plan.request.desc4') }}</span></li>
                    </ul>
                </UiCard>
                <UiCard>
                    <h3 class="text-center mb-4">{{ $t('business.offerARide') }}</h3>
                    <span class="text-center block text-5xl font-extrabold mb-4">{{ price }}€</span>
                    <div class="text-center items-center w-full content-center">
                        <p class="text-center text-base mb-1">{{ isYearly ? $t('business.yearly') : $t('business.monthly') }} <span class="text-[color:var(--secondary)]">/ {{ $t('business.freeTrial') }}</span></p>
                    </div>
                    <a :href="storeLink" target="_blank" class="download link-btn link-btn--dark mt-8">{{ $t('business.downloadApp') }}</a>
                    <ul class="offers">
                        <li><SvgoFaceGrinStars class="min-w-4" /> <span>{{ $t('business.plan.offer.desc1') }}</span></li>
                        <li><SvgoTruck class="min-w-4" /> <span>{{ $t('business.plan.offer.desc2') }}</span></li>
                        <li><SvgoCreditCard class="min-w-4" /> <span>{{ $t('business.plan.offer.desc3') }}</span></li>
                    </ul>
                </UiCard>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.container {
    @apply px-4 max-w-full my-24;
    &__inner {
        @apply max-w-7xl mx-auto;
        .plan {
            @apply flex justify-center flex-col md:flex-row items-center md:items-stretch gap-10 mt-12;
            .offers {
                @apply mt-[34px];
                li {
                    @apply flex gap-[10px] items-start;
                    &:not(:last-child) {
                        @apply mb-[18px];
                    }
                    svg {
                        @apply w-4 mt-1;
                    }
                }
            }
        }
        .download {
            width: 100% !important;
        }
    }
}
</style>